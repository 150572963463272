import './App.css';

import { logo, close, menu, qHeader, chevronDown, savaxBlue, tokens, igniteBlue } from './images';


function App() {
  return (
  <>
    <div id="sidebar-container">
      <div className="sidebar">
        <img
          src={close}
          className="h-5 w-5 ml-auto toggle-sidebar cursor-pointer mr-5"
          alt='close'
        />
        <hr className="sidebar-divider" />
        <div className="sidebar-link-container">
          <a
            href="https://app.b-enqi.fi/"
            className="sidebar-link toggle-sidebar"
            target="_blank" rel="noopener noreferrer"
          >
            Lend and borrow{" "}
          </a>
          <a
            href="https://staking.b-enqi.fi/"
            target="_blank" rel="noopener noreferrer"
            className="sidebar-link toggle-sidebar"
          >
            Liquid staking{" "}
          </a>
          <a
            href="https://ignite.benqi.fi/"
            target="_blank" rel="noopener noreferrer"
            className="sidebar-link toggle-sidebar"
          >
            Ignite{" "}
          </a>
          <a
            href="https://docs.benqi.fi/"
            target="_blank" rel="noopener noreferrer"
            className="sidebar-link toggle-sidebar"
          >
            Docs
          </a>
        </div>
      </div>
    </div>
    <section className="fold">
      <header className="header scrolling" id="header">
        <img
          className="header-logo logo"
          src={logo}
          alt="BENQI"
        />
        <div className="nav-container">
          <a href="https://app.b-enqi.fi/" target="_blank" rel="noopener noreferrer" className="nav-link">
            Lend and borrow
          </a>
          <a
            href="https://staking.b-enqi.fi/"
            target="_blank" rel="noopener noreferrer"
            className="nav-link"
          >
            Liquid staking
          </a>
          <a href="https://ignite.benqi.fi/" target="_blank" rel="noopener noreferrer" className="nav-link">
            Ignite
          </a>
          <a href="https://docs.benqi.fi/" target="_blank" rel="noopener noreferrer" className="nav-link">
            Docs
          </a>
        </div>
        <button className="apps-btn">Apps</button>
        <img className="menu toggle-sidebar" src={menu} alt='menu-toggle' />
      </header>
      <div className="fold-content">
        <div className="fold-content-copy-container">
          <div className="typewriter" data-typeit-id={2137938}>
            
            <span className="ti-cursor" data-ti-animation-id={2137938}>
              |
            </span>
          </div>
          <div className="fold-content-title">with BENQI</div>
          <div className="fold-content-description">
            The DeFi and Web3 protocol for all.
          </div>
        </div>
        <img className="q-header" src={qHeader} alt="q-header"/>
      </div>
      <img src={chevronDown} className="chevron-down" alt='chevron-down'/>
    </section>
    <section className="stat-container">
      <div>
        <div className="stat" id="total-tvl">
          $603,411,223.75
        </div>
        <div className="stat-label">of value locked</div>
      </div>
      <div>
        <div className="stat">100,000+</div>
        <div className="stat-label">users</div>
      </div>
      <div>
        <div className="stat">50+</div>
        <div className="stat-label">integrations</div>
      </div>
    </section>
    <section className="below">
      <div className="card-container">
        <div className="card">
          <div className="card-content-container relative">
            <div className="card-copy">
              <div className="card-title">
                <span>Liquid Staking</span>
              </div>
              <div className="card-description">
                Stake AVAX and earn. Seamlessly use it within DeFi.
              </div>
              <div className="card-tvl-link">
                <div className="mt-3">Total staked</div>
                <div
                  className="card-staked"
                  id="liquid-staking-tvl"
                  style={{ visibility: "visible" }}
                >
                  $295,469,832.83
                </div>
                <a
                  href="https://staking.b-enqi.fi/"
                  target="_blank" rel="noopener noreferrer"
                  className="card-footer-staking"
                >
                  Stake now <span className="card-arrow">→</span>
                </a>
              </div>
            </div>
            <img
              src={savaxBlue}
              className="absolute h-60 brightness-[0.3] bottom-3 -right-2 z-0"
              alt='savax-blue'
            />
          </div>
        </div>
        <div className="card">
          <div className="card-content-container">
            <div className="card-copy">
              <div className="card-title">
                <span>Markets</span>
              </div>
              <div className="card-description">
                Lend, borrow and earn with crypto.
              </div>
              <div className="card-tvl-link">
                <div className="mt-3">Total supplied</div>
                <div
                  className="card-staked"
                  id="lending-market-tvl"
                  style={{ visibility: "visible" }}
                >
                  $307,941,390.92
                </div>
                <a
                  href="https://app.b-enqi.fi/"
                  target="_blank" rel="noopener noreferrer"
                  className="card-footer-liquidity"
                >
                  Lend and borrow <span className="card-arrow">→</span>
                </a>
              </div>
            </div>
            <img
              src={tokens}
              className="absolute h-60 brightness-[0.3] bottom-3 -right-2 z-0"
              alt='tokens'
            />
          </div>
        </div>
        <div className="card">
          <div className="card-content-container">
            <div className="card-copy">
              <div className="card-title">
                <span>Ignite</span>
              </div>
              <div className="card-description">
                Run your own Avalanche validator or subnet
              </div>
              <a
                href="https://ignite.benqi.fi/"
                target="_blank" rel="noopener noreferrer"
                className="card-footer-ignite"
              >
                Start now <span className="card-arrow">→</span>
              </a>
            </div>
            <img
              src={igniteBlue}
              className="absolute h-60 brightness-[0.3] bottom-3 -right-2 z-0"
              alt='ignite-blue'
            />
          </div>
        </div>
      </div>
      <footer className="footer">
        <hr className="divider" />
        <div className="footer-content">
          <div className="footer-benqi">
            <img
              className="footer-logo"
              src={logo}
              alt="BENQI"
            />
            <div className="footer-manage-container">
              <span className="footer-manage">In collaboration with</span>
              <a
                href="https://romeblockchain.com/home"
                className="footer-manage-rbl"
                target="_blank" rel="noopener noreferrer"
              >
                Rome Blockchain Labs
              </a>
            </div>
            <div className="footer-copyright-desktop">
              Copyright ©️ 2021 - 2023 BENQI
              <span style={{ display: "block" }}>All Rights Reserved</span>
            </div>
          </div>
          <div className="footer-link-list">
            <div className="link-list-title">Analytics</div>
            <a
              href="https://community.chaoslabs.xyz/benqi"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              Chaos Labs{" "}
            </a>
            <a
              href="https://defirisk.intotheblock.com/metrics/avalanche/benqi"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              IntoTheBlock{" "}
            </a>
            <a
              href="https://defillama.com/protocol/benqi"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              DefiLlama{" "}
            </a>
            <a
              href="https://tokenterminal.com/terminal/projects/benqi"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              Token Terminal{" "}
            </a>
            <a
              href="https://bad-debt.riskdao.org/"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              Risk DAO
            </a>
          </div>
          <div className="footer-link-list">
            <div className="link-list-title">Resources</div>
            <a
              href="https://docs.benqi.fi/"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              Docs{" "}
            </a>
            <a
              href="https://github.com/Benqi-fi"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              GitHub{" "}
            </a>
            <a
              href="https://benqifinance.medium.com/"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              Medium{" "}
            </a>
            <a
              href="https://www.youtube.com/c/benqifinance"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              YouTube{" "}
            </a>
            <a
              href="https://docs.benqi.fi/resources/press-kit-logos-and-graphics"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              Media Kit{" "}
            </a>
            <a
              href="https://docs.benqi.fi/resources/risks#audits"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              Audits
            </a>
          </div>
          <div className="footer-link-list-last">
            <div className="link-list-title">Connect</div>
            <a
              href="https://twitter.com/BenqiFinance"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              Twitter{" "}
            </a>
            <a
              href="https://t.co/WkmdNK4Q0g"
              target="_blank" rel="noopener noreferrer"
              className="footer-link"
            >
              Discord
            </a>
            <div>Telegram</div>
            <a
              href="https://t.me/BENQIFinance"
              target="_blank" rel="noopener noreferrer"
              className="footer-link telegram"
            >
              Discussions (English){" "}
            </a>
            <a
              href="https://t.me/BENQI_zh"
              target="_blank" rel="noopener noreferrer"
              className="footer-link telegram"
            >
              Discussions (Chinese){" "}
            </a>
            <a
              href="https://t.me/benqifinance_announcements"
              target="_blank" rel="noopener noreferrer"
              className="footer-link telegram"
            >
              Announcements
            </a>
          </div>
        </div>
        <div className="footer-copyright-mobile">
          Copyright ©️ 2021 - 2023 BENQI, All Rights Reserved
        </div>
        <div className="legal">
          <hr className="divider" />
          <div className="legal-diclaimer">LEGAL DISCLAIMER</div>
          <div className="legal-content">
            The information provided on this website does not constitute
            investment advice, financial advice, trading advice, or any other sort
            of advice and you should not treat any of the website's content as
            such. The BENQI team provides the website as a service to the public,
            and is not responsible for, and expressly disclaims all liability for,
            damages of any kind arising out of use, reference to, or reliance on
            any information contained within this website. While the information
            contained within this website is periodically updated, no guarantee is
            given that the information provided in this website is correct,
            complete, and up-to-date.
          </div>
        </div>
      </footer>
    </section>
  </>

  );
}

export default App;
